export const table = [
    {
        column: '',
    },
    {
        column: 'Título',
    },
    {
        column: 'Palavras Chave',
    },
    {
        column: 'mentorados',
    },
    {
        column: 'Valor',
    },
    // {
    //     column: 'Avaliações',
    // },
    {
        column: 'Status',
    },
    {
        column: 'Ações',
    }
];
// 'evaluations'
export const keys = ['img', 'title', 'tags', 'mentees_count', 'value', 'status', 'actions'];
