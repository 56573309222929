<script>

export default {
    props: {
        data: {
            type: Object,
            required: true
        },
        url: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            load: false,
            copy: {}
        }
    },

    methods: {
        
    },

    created() {
        this.copy = JSON.parse(JSON.stringify(this.data));
    }
}


</script>

<template>
    <b-badge class=" fs-14 p-2 " :variant="copy.status == 1 ? 'success' : 'danger'"
             >
        <b-spinner v-if="load"></b-spinner>
        <span v-else>
            {{ copy.status == 1 ? 'Ativa' : 'Inativa' }}
        </span>
    </b-badge>
</template>
<!-- teste-->
